import React, { Fragment } from 'react';
import Slider, { LazyLoadTypes } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ModalBox } from '../ModalBox';
import { ISliderProps, ISlide } from './types';
import {
  SliderWrapper,
  CarouselInner,
  CorpContentSliderWrapper,
  CarouselHeading,
  Caption,
  CarouselContentWrapper,
  StyledPlayCorpContentSlider,
  VideoSlideContent,
} from './styles';

import { Title, Text } from '../Typography';
import { MetaBallContainer } from '../MetaBallContainer';
import { Picture } from '../../modules/responsivePicture';
import { getPictureSources } from '../../modules/getPictureSources';
import GetSafari from '../../modules/getSafari';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export const CorpContentSlider = ({
  slides,
  heading,
  title,
  slidesToShow,
  backgroundImg,
  bottomLeftAsset,
  topRightAsset,
}: ISliderProps) => {
  const sliderSettings = {
    dots: true,
    lazyLoad: 'ondemand' as LazyLoadTypes,
    infinite: false,
    speed: 500,
    centerMode: true,
    centerPadding: '20%',
    slidesToShow,
    slidesToScroll: slidesToShow,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0',
          arrows: false,
        },
      },
    ],
  };

  const slideType = (sliderType: string, image?: Record<string, string>, videoId?: string) => {
    switch (sliderType) {
      case 'video': {
        return (
          <VideoSlideContent>
            <StyledPlayCorpContentSlider>
              <ModalBox videoID={videoId} />
            </StyledPlayCorpContentSlider>
            <Picture sources={getPictureSources(image)} alt="" />
          </VideoSlideContent>
        );
      }
      case 'image': {
        return <Picture sources={getPictureSources(image)} alt="" />;
      }
      default: {
        return <Picture sources={getPictureSources(image)} alt="" />;
      }
    }
  };
  const isSafari = GetSafari();
  const backgroundImage = isSafari ? backgroundImg?.backgroundFallback : backgroundImg?.background;

  return (
    <MetaBallContainer
      data-test="content-slider-metaball-container"
      topRightImage={topRightAsset?.url}
      bottomLeftImage={bottomLeftAsset?.url}
    >
      <CorpContentSliderWrapper
        data-test="content-slider-content-slider-wrapper"
        style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined }}
      >
        <CarouselHeading data-test="content-slider-carousel-heading">
          {heading && (
            <Text type="sm" tag="p" className="uppercase pb-8">
              {heading}
            </Text>
          )}
          {title && (
            <Title type="sm" tag="h3" className="mb-6">
              {title}
            </Title>
          )}
        </CarouselHeading>
        <SliderWrapper data-test="content-slider-slider-wrapper">
          <Slider data-test="content-slider-slider" {...sliderSettings}>
            {slides &&
              slides.length > 0 &&
              slides.map(({ videoId, caption, slideAsset, id }: ISlide) => (
                <Fragment key={id}>
                  <CarouselContentWrapper data-test="content-slider-carousel-content-wrapper">
                    <CarouselInner
                      data-test="content-slider-carousel-inner"
                      className="lg:h-heroInner md:h-300 sm:h-heroInnerS overflow-hidden relative"
                    >
                      {videoId
                        ? slideType('video', slideAsset, videoId)
                        : slideType('image', slideAsset)}
                    </CarouselInner>
                    {caption && <Caption data-test="content-slider-caption">{caption}</Caption>}
                  </CarouselContentWrapper>
                </Fragment>
              ))}
          </Slider>
        </SliderWrapper>
      </CorpContentSliderWrapper>
    </MetaBallContainer>
  );
};

CorpContentSlider.defaultProps = {
  slidesToShow: 1,
};
